import React, { createContext, useContext, useState } from 'react';

const ConversationContext = createContext();

export function ConversationProvider({ children }) {
  const [conversationUpdateTrigger, setConversationUpdateTrigger] = useState(0);

  const triggerConversationUpdate = () => {
    setConversationUpdateTrigger(prev => prev + 1);
  };

  return (
    <ConversationContext.Provider value={{ conversationUpdateTrigger, triggerConversationUpdate }}>
      {children}
    </ConversationContext.Provider>
  );
}

export function useConversation() {
  return useContext(ConversationContext);
}
