import { useUser } from '../contexts/UserContext';
import { useLocalStorage } from './LocalStorage';

const useStreamApi = () => {
    const { user, setUser } = useUser();
    const [localStorageUser] = useLocalStorage('user', null);

    const baseUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api`;

    const refreshAccessToken = async (refreshToken) => {
        try {
            const response = await fetch(`${baseUrl}/refresh-token/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ refresh: refreshToken }),
            });

            if (!response.ok) {
                throw new Error('Failed to refresh token');
            }

            const data = await response.json();

            // Update user context and localStorage
            const newAccessToken = data.access;
            setUser({
                access: newAccessToken,
                refresh: refreshToken,
                email: localStorageUser?.email || data?.display || '',
            });

            return newAccessToken;
        } catch (error) {
            setUser(null);
            window.location.href = '/login';
            throw error;
        }
    };

    const fetchWithAuth = async (url, options = {}) => {
        const token = user?.access;

        const headers = {
            'Content-Type': 'application/json',
            ...options.headers,
        };

        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }

        const config = { ...options, headers };

        let response = await fetch(baseUrl + url, config);

        // Handle 401 errors and attempt token refresh
        if (response.status === 401 && user?.refresh) {
            const refreshToken = user.refresh;

            try {
                const newAccessToken = await refreshAccessToken(refreshToken);

                // Retry original request with new token
                headers.Authorization = `Bearer ${newAccessToken}`;
                response = await fetch(baseUrl + url, { ...options, headers });
            } catch (error) {
                console.error('Failed to refresh token', error);
                throw error;
            }
        } else if (response.status !== 200) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Server error occurred');
        }

        return response;
    };

    const fetchStream = async (url, options = {}, onChunk, onDone=()=>{}) => {
        const response = await fetchWithAuth(url, options);

        if (!response.body) {
            throw new Error('ReadableStream is not supported in this environment.');
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let result = '';

        // Process streamed chunks
        while (true) {
            const { done, value } = await reader.read();

            if (done) {
                onDone(result);
                break;
            }

            const chunk = decoder.decode(value, { stream: true });
            result += chunk;

            // Callback for processing the chunk
            if (onChunk) {
                onChunk(chunk);
            }
        }

        return result;
    };

    return {
        fetchWithAuth,
        fetchStream,
    };
};

export default useStreamApi;
