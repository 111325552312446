import React from 'react';
import Drawer from "./Drawer";
import { useNavigate } from 'react-router-dom';  // Add this import


function Header() {
  const navigate = useNavigate();

  return (
    <header className="border-bottom d-flex justify-content-between sticky-top bg-white">
      <div className="px-2 py-2">
        <span role="button" data-bs-toggle="offcanvas" data-bs-target="#global-drawer" className="bi-list fs-3"></span>
        <span 
          role="button" 
          className="mx-4 bi-pencil-square fs-3" 
          data-toggle="tooltip" 
          data-bs-placement="bottom" 
          title="New Chat"
          onClick={() => window.location.href = '/chat'}
        ></span>

      </div>
      <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
        <img width={120} src="https://ucarecdn.com/54a4442b-6c20-409b-b6d5-4f1eb3c5496e/-/resize/120x32/" alt="TubeGPT"/>
      </a>

      <Drawer/>
    </header>
  );

}

export default Header;
