// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.conversation-item {
  transition: background-color 0.2s ease;
  border-radius: 6px;
}

.conversation-item.semi-active {
  background-color: rgba(0, 0, 0, 0.03);
}

.conversation-item:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.conversation-item.active {
  background-color: rgba(0, 0, 0, 0.1);
}


.conversation-item a {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,kBAAkB;AACpB;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,oCAAoC;AACtC;;;AAGA;EACE,cAAc;AAChB","sourcesContent":[".conversation-item {\n  transition: background-color 0.2s ease;\n  border-radius: 6px;\n}\n\n.conversation-item.semi-active {\n  background-color: rgba(0, 0, 0, 0.03);\n}\n\n.conversation-item:hover {\n  background-color: rgba(0, 0, 0, 0.08);\n}\n\n.conversation-item.active {\n  background-color: rgba(0, 0, 0, 0.1);\n}\n\n\n.conversation-item a {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
