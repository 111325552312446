import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useUser } from "../contexts/UserContext";
import useApi from "../utils/useApi";
import { useConversation } from "../contexts/ConversationContext";


function Drawer() {
  const currentPath = window.location.pathname;
  const { user, setUser } = useUser();
  const [conversations, setConversations] = useState([]);
  const [loadingConversations, setLoadingConversations] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const { conversationUpdateTrigger } = useConversation();
  const location = useLocation()
  const navigate = useNavigate();

  const api = useApi();

  const fetchConversations = async () => {
    if(loadingConversations) {
      return;
    }
    setLoadingConversations(true);
    try {
      const response = await api.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/conversations/`);
      setConversations(response.data || []);
    } catch (error) {
      console.error('Failed to fetch conversations:', error);
      setConversations([]);
    } finally {
      setLoadingConversations(false);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    setCurrentId(urlParams.get('id'));
  }, [location.search]);

  useEffect(() => {
    if (user) {
      fetchConversations();
    } else {
      setConversations([]);
    }
  }, [user, conversationUpdateTrigger]);

  // useEffect(() => {
  //   if (currentId && (conversations.length === 0 || !conversations.some(conv => conv.uuid === currentId))) {
  //     navigate('/chat');
  //   }
  // }, [currentId, conversations]);

  return (
    <div className="offcanvas offcanvas-start" tabIndex="-1" id="global-drawer"
         aria-labelledby="offcanvasExampleLabel"
         style={{ maxWidth: '18rem' }}
    >
      <div className="offcanvas-header py-2 px-3">
        <span role="button" className="bi-list fs-2" data-bs-dismiss="offcanvas" aria-label="Close"></span>
      </div>

      <div className="offcanvas-body px-3">
        <a href="/" className="d-flex justify-content-center align-items-center mb-3 text-dark text-decoration-none">
          <img
            width={160}
            src="https://ucarecdn.com/54a4442b-6c20-409b-b6d5-4f1eb3c5496e/"
            alt="TubeGPT"
          />
        </a>

        {loadingConversations && (
          <div className="text-center">
            <div className="spinner-border spinner-border-sm text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}

        {(
          <>
            <h6 style={{fontSize: '.7rem'}} className='mt-4 fw-bold border-bottom pb-2'>💬 Previous Conversations</h6>
            {conversations.length < 1 && (
              <h5 className='text-muted small'>
                {user ? "Start your first conversation! 🚀" : "Sign in to unlock your chat history! ✨"}
              </h5>
          )}

            <ul className="list-unstyled text-start overflow-scroll">
              <li className={`my-2 p-1 conversation-item ${!currentId ? 'active' : 'semi-active'}`}>
                <a
                  className={`nav-link my-1 text-nowrap ${!currentId ? 'active' : 'link-secondary'}`}
                  href="/chat"
                >
                  <i className='bi-pencil-square'> </i> New Chat
                </a>
              </li>

              {
                conversations.map((conv, index) => (
                  <li 
                    className={`my-2 p-1 conversation-item ${conv.uuid === currentId ? 'active' : ''}`}
                    key={index}
                  >
                    <Link
                      className={`nav-link my-1 text-nowrap ${conv.uuid === currentId ? 'active' : 'link-secondary'}`}
                      to={`/chat?id=${conv.uuid}`}
                    >
                      {conv.title}
                    </Link>
                  </li>
                ))
              }
            </ul>
          </>
        )}
      </div>
      
      <div className="offcanvas-bottom text-center border-top">
      <span style={{cursor: 'default'}}
            className="fst-italic text-secondary align-items-center justify-content-center mb-md-0 me-md-auto link-body-emphasis text-decoration-none">

          {user ? (
            <div className="my-4 text-center">
              <p className="text-muted mb-1">{user.email}</p>
              <button
                className="btn btn-outline-danger"
                onClick={() => {setUser(null); window.location.href = '/chat'}}
              >
                Sign Out
              </button>
            </div>
          ) : currentPath !== '/login' && (
            <div className="mt-4 text-center">
              <a
                href="/login"
                className="btn btn-outline-primary"
              >
                Sign In
              </a>
            </div>
          )}
          
          Gaza Victorious &#127477;&#127480;
      </span>
      </div>
      
    </div>
  );
}

export default Drawer;
