import React, { useState, useEffect } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import '../ChatPage.css';
import Header from "../components/Header";
import { useUser } from "../contexts/UserContext";
import useApi from "../utils/useApi";
import useStreamApi from '../utils/useStreamApi';
import { useConversation } from "../contexts/ConversationContext";


function isRTL(text) {
  const rtlChars = /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/;
  return rtlChars.test(text[0]);
}

function formatMessageContent(content) {
  content = content.trim().replace(/\r\n/g, '\n');

  // Handle code blocks with backticks
  content = content.replace(/```(.*?)\n([\s\S]*?)```/g, (match, language, code) => {
    return `<div class="code-block"><div class="code-language">${language}</div><pre><code>${code}</code></pre></div>`;
  });
  
  // Handle inline code with single backticks
  content = content.replace(/`([^`]+)`/g, '<code>$1</code>');
  
  // Handle numbered lists - add <br/> before each item except if it's the first item
  content = content.replace(/^\d+\.\s/m, '$&'); // Keep first item as-is
  content = content.replace(/\n\d+\.\s/g, '<br/>$&'); // Add <br/> to subsequent items
  
  // Handle bold text
  content = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  
  // Replace multiple newlines with a single <br/>
  content = content.replace(/\n{2,}/g, '<br/>');
  
  // Convert remaining single newlines to <br/>
  content = content.replace(/\n/g, '<br/>');

  // Replace three consecutive <br> tags (with optional spaces) with two <br> tags
  content = content.replace(/(<br\s*\/?>\s*){2,}/g, '<br/><br/>');
  
  return content;
}

function ChatPage() {
  const [touching, setTouching] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [userMessage, setUserMessage] = useState('');
  const [systemMessage, setSystemMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [querying, setQuerying] = useState(false);
  const [loadingConversationMessages, setLoadingConversationMessages] = useState(false);
  const [rateLimitReached, setRateLimitReached] = useState(false);
  const [conversationID, setConversationID] = useState(null);
  const { user } = useUser(null);
  const { triggerConversationUpdate } = useConversation();
  
  const [conversationTitle, setConversationTitle] = useState('');
  const [updatingConversationTitle, setUpdatingConversationTitle] = useState(false);
  

  const location = useLocation()
  const api = useApi();
  const { fetchStream } = useStreamApi();

  const MAX_WIDTH = 52;

  const messagesDivRef = React.useRef(null);

  const navigate = useNavigate();

  const loadConversationMessages = async () => {
    if (!conversationID) {
      return;
    }
    setLoadingConversationMessages(true);

    try {
      const response = await api.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/conversations/${conversationID}/messages/`);
      if (response.data) {
        const allMessages = response.data.flatMap(msg => [
          { role: 'user', content: msg.message_user },
          { role: 'system', content: msg.message_system }
        ]);
        setMessages(allMessages);
      }
    } catch (error) {
      console.error('Failed to fetch messages:', error);
    } finally {
      setLoadingConversationMessages(false);
    }
  }

  const loadConversationMeta = async () => {
    setVideoUrl('');

    if (!conversationID) {
      return;
    }

    try {
      const response = await api.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/conversations/${conversationID}/`);
      if (response.data) {
        setVideoUrl(response.data.youtube_url);
      }
    } catch (error) {
      console.error('Failed to fetch conversation meta:', error);
    }
  }

  const handleTouchStart = () => setTouching(true);

  const handleTouchEnd = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollHeight - (scrollTop + clientHeight) <= 100) {
      setTouching(false);
    }
  };

  const handleWheel = (e) => {
    if (e.deltaY === 0) {
      return;
    }
    setTouching(true);

    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollHeight - (scrollTop + clientHeight) <= 5) {
      setTouching(false);
    }
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    loadConversationMeta();
    loadConversationMessages();
  }, [conversationID])

  // Listen on scrolls to allow users to read peacefully
  useEffect(() => {
    window.addEventListener('wheel', handleWheel, { passive: false });
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  // Scroll to the bottom if the user added a new message
  useEffect(() => {
    if (messagesDivRef.current && messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      
      if (lastMessage.role === 'user') {
        setTouching(false);
        window.scroll({
          top: messagesDivRef.current.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [messages]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const paramId = urlParams.get('id');
    if (conversationID !== paramId) {
      setConversationID(paramId);
    }
  }, [location.search, conversationID]);

  useEffect(() => {
    if (messagesDivRef.current && !touching) {
      window.scroll({
        top: messagesDivRef.current.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [messagesDivRef, messages, touching]);

  useEffect(() => {
    if (!user || !conversationID || conversationTitle || updatingConversationTitle) {
      return;
    }

    setUpdatingConversationTitle(true);
    api.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/conversations/${conversationID}/update-title/`)
      .then((data) => {
        setConversationTitle(data.data.title);
        triggerConversationUpdate();
      })
      .catch(e => console.error('Failed to update conversation title:', e))
      .finally(() => setUpdatingConversationTitle(false));

  }, [conversationID])

  const handleSendMessage = async () => {
    const localUserMessage = userMessage;
    setUserMessage('');
    if (querying || videoUrl.length < 1 || localUserMessage.length < 1 || rateLimitReached) {
      return;
    }

    try {
      window.gtag('event', 'send_chat_message', { message: localUserMessage });
    } catch (err) {}

    try {
      setQuerying(true);
      setMessages((prev) => [
        ...prev,
        { role: 'user', content: localUserMessage },
      ]);

      const apiEndpoint = user
        ? `/v2/transcript-chat/`
        : `/v2/transcript-chat/anonymous/`;

      // Use Fetch to send the POST request and handle streaming response
      try {
        await fetchStream(
          apiEndpoint,
          {
            method: 'POST',
            body: JSON.stringify({
              video_url: videoUrl,
              user_message: localUserMessage,
              conversation_id: conversationID,
            })
          },
          (chunk) => {
            const subChunks = chunk.split('\n\n');
            subChunks.forEach(subChunk => {
              if (!subChunk.startsWith('data:')) {
                return
              }
              const jsonData = subChunk.replace('data:', '').trim();
              let parsedData = {}
              try {
                parsedData = JSON.parse(jsonData);
              } catch (err) {
                console.error('Failed to parse streamed message:', err);
              }
              setSystemMessage((prev) => prev + (parsedData.content || ''))
              
            })
          },
          (result) => {
            if (!conversationID && result) {
              const lines = result.split('\n\n');
              const lastDataLine = lines.reverse().find(line => line.trim().startsWith('data:'));
              if (lastDataLine) {
                  try {
                      const sample = JSON.parse(lastDataLine.replace('data:', '').trim());
                      setConversationID(sample.conversation_id);
                      navigate(`?id=${sample.conversation_id}`);
                  } catch (error) {
                      console.error("Failed to parse JSON from last data line:", error);
                  }
              }
            }
          }
        );
      } catch (error) {
        setMessages((prev) => [
          ...prev,
          { role: 'system', content: error.message },
        ]);  
      }
    } catch (error) {
      console.error(error);
      setMessages((prev) => [
        ...prev,
        { role: 'system', content: 'Oops! Something went wrong!' },
      ]);
    } finally {
      setQuerying(false);
      setSystemMessage('');
    }
  };

  useEffect(() => {
    if (user) {
      setRateLimitReached(false);
    }
  }, [user]);

  useEffect(() => {
    if (!systemMessage) return;

    setMessages(prev => {
      const lastMessage = prev[prev.length - 1];
      
      if (lastMessage?.role === 'user') {
        // If the last message was from the user, create a new system message
        return [...prev, { role: 'system', content: systemMessage }];
      } else if (lastMessage?.role === 'system') {
        // Update the last system message
        const updatedMessages = [...prev];
        updatedMessages[updatedMessages.length - 1].content = systemMessage;
        return updatedMessages;
      }
      
      return prev;
    });
  }, [systemMessage]);

  const showLoader = loadingConversationMessages && messages.length < 1;

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />

      <main
        className={`container my-3 flex-grow-1 d-flex flex-column ${(messages.length === 0 || showLoader) && 'align-items-center justify-content-center'}`}
        ref={messagesDivRef}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        style={{maxWidth: `${MAX_WIDTH}rem`}}
        >

      {showLoader && (
        <div className="text-center">
          <div className="spinner-border spinner-border-sm text-secondary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

        {/* Display a fun introductory message if no messages have been sent */}
        {messages.length === 0 && !showLoader && (
          <div className="text-center">
            <h5 className="mb-3 text-muted">Welcome! 👋</h5>
            <p className="text-muted">Paste a YouTube link to start chatting about the video!</p>
            <p className="text-muted">Try asking, “What is it talking about?”</p>
          </div>
        )}

        {/* Render the messages if there are any */}
        {!showLoader && messages.map((msg, index) => (
          <div key={index}
               className={`px-2 d-flex ${index === 0 && `mt-auto`} ${msg.role === 'user' ? 'text-white flex-row-reverse' : ''}`}>
            <p style={{
              width: msg.role === 'system' ? '100%' : 'auto',
              direction: isRTL(msg.content) ? 'rtl' : 'ltr',
              textAlign: isRTL(msg.content) ? 'right' : 'left'
            }}
               className={`p-2 rounded-4 ${msg.role === 'user' ? 'bg-primary' : 'bg-white'}`}
               dangerouslySetInnerHTML={{ __html: formatMessageContent(msg.content) }}
               />
          </div>
        ))}

      </main>

      <div style={{height: '12rem'}}></div>
      <footer style={{maxWidth: `${MAX_WIDTH}rem`}}
              className="shadow-lg rounded-top-4 pt-1 pb-1 fixed-bottom bg-white mx-auto z-1">
        <div className="container">
          <div style={{minHeight: '1.5rem'}}>
            {querying ? (
              <div className={`spinner-border spinner-border-sm mx-2 text-muted`} role="status">
                <span className="sr-only"></span>
              </div>
            ) : null}
          </div>

          <input
            className="form-control w-100 mb-3"
            type="text"
            placeholder="https://youtu.be/2UyxRgapLxA"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
            disabled={(!user && rateLimitReached) || (conversationID?.length > 0 && videoUrl)}
          />

          <hr />

          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="What is it talking about?"
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              onKeyDown={(event) => {
                const disabled = querying || videoUrl.length < 1 || userMessage.length < 1 || (!user && rateLimitReached);
                if (disabled) {
                  return;
                }
                if (event.key === 'Enter') {
                  event.preventDefault();
                  handleSendMessage();
                }
              }}
              disabled={!user && rateLimitReached}
            />
            <button disabled={querying || videoUrl.length < 1 || userMessage.length < 1 || (!user && rateLimitReached)}
                    className="btn btn-outline-primary" type="button" onClick={handleSendMessage}>Send
            </button>
          </div>

          <p className="text-muted text-center mb-0 mt-2 small">AI can make mistakes, double check information</p>

        </div>
      </footer>
    </div>
  );
}

export default ChatPage;
