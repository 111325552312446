import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ChatPage from './pages/ChatPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import PrivacyPolicy from "./components/PrivacyPolicy";
import {UserProvider} from "./contexts/UserContext";
import { ConversationProvider } from './contexts/ConversationContext';
import TermsOfUse from "./components/TermsOfUse";

import './App.css';

function App() {
  return (
    <ConversationProvider>
      <UserProvider>
        <Router>
          <div className="App w-100">
            <Routes>
              <Route path="/" element={<Navigate to="/chat" replace />} />
              <Route path="/chat" element={<ChatPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-use" element={<TermsOfUse />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
            </Routes>
          </div>
        </Router>
      </UserProvider>
    </ConversationProvider>
  );
}

export default App;
